<template>
  <div class="uk-width-1-1 uk-height-1-1 bg-white">
    <div
      class="uk-child-width-1-2@s uk-child-width-1-2@m uk-text-center uk-margin-remove"
      uk-grid
    >
      <div class="section-cover-login">
        <div class="bg-login">
          <img
            :src="`${images}/bg-login.png`"
            alt="bg-login"
          >
        </div>
        <div class="uk-margin-medium-top uk-text-left">
          <h1 class="headline-login">
            Manage and monitor the process of coffee
          </h1>
        </div>
      </div>

      <div>
        <div class="section-login uk-flex-column">
          <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <img
              :src="`${images}/logo-varion.png`"
              alt="logo-varion"
              width="238"
              height="58"
            >
            <h4 class="logo-text uk-margin-large-bottom">
              Admin Dashboard
            </h4>
          </div>
          <form
            class="uk-form-auth"
            @submit.prevent="onSubmit"
          >
            <fieldset class="uk-fieldset uk-text-left">
              <h3
                v-if="isActivation === true"
                class="uk-legend"
              >
                Create Password
              </h3>
              <h3
                v-else
                class="uk-legend"
              >
                Reset Password
              </h3>
              <div class="uk-margin uk-text-left parent-password-form">
                <div class="uk-inline uk-width-1-1">
                  <img
                    class="uk-form-icon-custom uk-form-icon-flip"
                    :src="`${images}/icon/${eyeIcon}`"
                    alt="eye-on-icon"
                    @click="passwordVisibility"
                  >
                  <label>New Password</label>
                  <input
                    id="password"
                    v-model="password"
                    v-validate="'required|min:8'"
                    name="password"
                    class="uk-input"
                    :type="passwordFieldType"
                    placeholder="Enter New Password"
                    autocomplete="off"
                    :class="{'uk-form-danger': errors.has('password')}"
                  >
                </div>
                <span
                  v-show="errors.has('password')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('password') }}</span>
              </div>

              <div class="uk-margin uk-text-left parent-password-form">
                <div class="uk-inline uk-width-1-1">
                  <img
                    class="uk-form-icon-custom uk-form-icon-flip"
                    :src="`${images}/icon/${eyeIconConfirm}`"
                    alt="eye-on-icon"
                    @click="confirmPasswordVisibility"
                  >
                  <label>Confirm New Password</label>
                  <input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    v-validate="'required|min:8'"
                    name="confirmPassword"
                    class="uk-input"
                    :type="confirmPasswordFieldType"
                    placeholder="Re-enter New Password"
                    autocomplete="off"
                    :class="{'uk-form-danger': errors.has('confirmPassword')}"
                  >
                </div>
                <span
                  v-show="errors.has('confirmPassword')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('confirmPassword') }}</span>
              </div>
              
              <button
                v-if="!getToggleModalAddEditDiscard.loading"
                class="uk-button uk-button-primary uk-width-1-1"
                type="submit"
              >
                Save
              </button>
              <button
                v-else
                class="uk-button uk-button-primary uk-width-1-1"
                type="button"
                disabled
              >
                <div uk-spinner />
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <modal-auth-info
      :images="images"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '../../utils/constant'
import { notificationDanger } from '@/utils/notification'
import ModalAuthInfo from '@/components/globals/modals/ModalAuthInfo'

export default {
  components: {
    ModalAuthInfo
  },
  data() {
    return {
      token: '',
      otpCode: '',
      isActivation: false,
      images: PREFIX_IMAGE,
      is_error: false,
      password: '',
      confirmPassword: '',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      eyeIcon: 'ic_eye_off.svg',
      eyeIconConfirm: 'ic_eye_off.svg'
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'auth/get_toggle_modal_add_edit_discard'
    })
  },
  mounted() {
    if (this.$route?.query?.reset_token) {
      this.token = this.$route?.query?.reset_token
      this.otpCode = this.$route?.query?.code
      this.isActivation = false
    } else {
      this.token = this.$route?.query?.activation_token
      this.otpCode = this.$route?.query?.code
      this.isActivation = true
    }
  },
  methods: {  
    ...mapActions({
      actionResetPassword: 'auth/resetPassword',
      actionResetFirstLogin: 'auth/resetPasswordFirstLogin'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    passwordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.eyeIcon = this.eyeIcon === 'ic_eye_on.svg' ? 'ic_eye_off.svg' : 'ic_eye_on.svg'
    },
    confirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
      this.eyeIconConfirm = this.eyeIconConfirm === 'ic_eye_on.svg' ? 'ic_eye_off.svg' : 'ic_eye_on.svg'
    },
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.password === this.confirmPassword) {
            if (this.$validator.errors.any()) return
            this.handleResetPassword()
          } else {
            if(!this.is_error) {
              this.is_error = true
              notificationDanger({message: 'Password Harus Sama'})
              setTimeout(() => {
                this.is_error = false
              }, 2000)
            }

          }
        }
      })
    },
    async handleResetPassword() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: true})
      // Reset Password //
      if(this.$route?.query?.reset_token){
        this.actionResetPassword({new_password: this.password, confirm_password: this.confirmPassword, token: this.token, otp: this.otpCode}).then(result => {
          if(result?.message == 'OK') {
            setTimeout(() => {
              this.setToggleModalAddEditDiscard({
                ...this.getToggleModalAddEditDiscard, 
                targetModals: true,  
                title: 'Reset Password Successful',
                type: 'reset-password',
                route: 'Login',
                customTitleBtnOk: 'Go to Login',
                loading: false
              })
            }, 500)
          } else {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard, 
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              route: '',
              customTitleBtnOk: '',
              loading: false
            })
          }
        })
      } else {
        // Reset Password First Login //
        this.actionResetFirstLogin({new_password: this.password, confirm_password: this.confirmPassword, token: this.token, activate_user_otp: this.otpCode}).then(result => {
          if(result?.message == 'OK') {
            setTimeout(() => {
              this.setToggleModalAddEditDiscard({
                ...this.getToggleModalAddEditDiscard, 
                targetModals: true,  
                title: 'Reset Password Successful',
                type: 'activated-user',
                route: 'Login',
                customTitleBtnOk: 'Go to Login',
                loading: false
              })
            }, 500)
          } else {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard, 
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              route: '',
              customTitleBtnOk: '',
              loading: false
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>
 .uk-margin.uk-text-left {
    margin-top: 24px !important;
  }
  h3 {
    font-family: 'interBold';
    font-weight: 700;
    font-size: 32px;
    color: #000000;
  }
  h4 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  label {
    display: inline-block;
    margin-bottom: 5px;
    font-family: 'interRegular';
    font-size: 16px !important;
    color: #000000 !important;
  }
  .uk-legend {
    margin-bottom: 0;
  }
  .parent-password-form {
    position: relative;
  }
  .uk-form-icon-custom {
    position: absolute;
    top: 45px;
    width: 24px;
    height: 24px;
    right: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #999;
    cursor: pointer;
  }
</style>
